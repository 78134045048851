import { render, staticRenderFns } from "./paibanSetting.vue?vue&type=template&id=e2d5de66&scoped=true&"
import script from "./paibanSetting.vue?vue&type=script&setup=true&lang=ts&"
export * from "./paibanSetting.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./paibanSetting.vue?vue&type=style&index=0&id=e2d5de66&prod&lang=less&scoped=true&"
import style1 from "./paibanSetting.vue?vue&type=style&index=1&id=e2d5de66&prod&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e2d5de66",
  null
  
)

export default component.exports