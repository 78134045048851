import { toDecimal, uniquePlankNum } from '@/util/commonFuncs'
import { dealPlankListLabelID } from '@/util/dealPaibanData.js'
import { dealGuimenFGNo, dealMaterialList } from '@/util/plankCommonFuncs'
import { nanoid } from 'nanoid'

import type { PartType, PlankType } from '../types/part'

interface DealDataParams {
  data?: PartType[]
  originPlankList?: PlankType[]
  orderInfo?: any | null
  isFold?: boolean
  isLoadMore?: boolean
  isClearHSInfo?: boolean // 导入了csv，xlx需要将孔槽信息清空
  isToggle?: boolean // 切换页面的时候需要就需要将板件的选中状态保存，其余的时候将颁奖状态设置为true
}

// 对数据做通用处理
export const dealData = ({
  data = [],
  originPlankList = [],
  orderInfo = {},
  isFold = true,
  isLoadMore = false,
  isClearHSInfo = false,
  isToggle = false,
}: DealDataParams) => {
  // const copyOriginPlankList = cloneDeep(originPlankList)
  // const copyData = cloneDeep(data)
  let arr: PlankType[] = []
  const orderNo_address: any = []
  const plankMap = new Map()
  const tempSamPleData: Pick<
    PartType,
    keyof {
      address: string
      customer: string
      orderNo: string
      roomName: string
      remarks: string
    }
  > = {
    address: '',
    customer: '',
    orderNo: '',
    roomName: '',
    remarks: '',
  }
  if (isLoadMore) {
    originPlankList.forEach((item) => {
      item.parts.forEach((part) => {
        data.push(part)
      })
    })
  }
  for (let i = 0; i < data.length; ++i) {
    let plank = data[i]
    const isSelected = plank.isSelected
    plank.isUnfold = !isFold
    // 记录板件数量, 默认为1
    plank.amount = plank.amount ? Number(plank.amount) : 1
    // 记录板件成品宽度和成品长度
    plank.specWidth = toDecimal(plank.oRect.width, 2)
    plank.specHeight = toDecimal(plank.oRect.height, 2)
    // 计算所有的孔槽数量和侧孔数量之和
    let holeNum = plank.holes.length + plank.sholes.length
    // 计算所有的拉槽数量和侧槽数量之和
    let slotNum = plank.slots.length + plank.sslots.length
    if (plank.curveHoles)
      slotNum += plank.curveHoles.filter(
        (hole) => hole.disType == 'CCCurveSlot'
      ).length
    // 记录板件的孔槽信息
    // 本地导入的板件, 孔槽信息设为0，csv，xls文件没有孔槽信息
    plank.hsInfo = isClearHSInfo ? '0孔0槽' : `${holeNum}孔${slotNum}槽`

    // 处理板件的封边信息
    if (!plank.hasOwnProperty('frontEdge') && plank.edgeInfo) {
      plank = { ...plank, ...splitEdgeInfo(plank.edgeInfo) }
    }
    // 记录板件是否为异形板件
    plank.specialShape = plank.path ? true : false
    // 板件默认选中
    plank.isSelected = !isToggle ? true : !!isSelected
    // 将is_high_gloss_plank转为boolean
    // 存在老数据中没有is_high_gloss_plank的情况，就通过使用matCode来判断是否是高光
    plank.is_high_gloss_plank = !!plank.is_high_gloss_plank
    plank.matCode = plank.matCode.replace(/高光_/g, '')
    // 板件唯一标识
    plank.partUniqueId = plank.partUniqueId ?? nanoid()
    plank.oriPartUniqueId = plank.partUniqueId ?? nanoid()
    // 高光板标识
    const high_plank = plank.is_high_gloss_plank
    // 记录该板件的材质厚度颜色标识, 以便后续的判断
    plank.typeSymbol = `${plank.matCode}:${plank.thick}:${plank.texture}`
    // 板件默认不是编辑状态
    plank.isEditing = false
    // 将板件厚度转为数字类型
    plank.thick = Number(plank.thick)
    // 每个板件添加特殊标识
    plank.checklistID = i + 1
    const simplePlankNumObj = generateSimplePlankNum(plank)
    if (simplePlankNumObj) {
      plank.simplePlankNum = simplePlankNumObj.simplePlankNum
      plank.simplePlankNumF = simplePlankNumObj.simplePlankNumF
    }
    // 生产唯一的plankNum
    const plankNum = uniquePlankNum.createUniquePlankNum(plank as any)
    plank.plankNum = plankNum
    plank.oriPlankNum = plank.oriPlankNum ? plank.oriPlankNum : plankNum
    plank.customer_name = plank.customer_name ?? plank.customer
    // 为每一个孔槽添加唯一ID
    plank?.holes.forEach((it) => (it._conflict_uniqueId = nanoid()))
    plank?.slots.forEach((it) => (it._conflict_uniqueId = nanoid()))
    plank.plank_remarks = plank.plank_remarks || ''
    plank.handleSlopes &&
      plank.handleSlopes.forEach(
        (it) => ((it._conflict_uniqueId = nanoid()), (it.width = 10))
      )
    plank.curveHoles = plank.curveHoles ?? []
    // 存在长圆孔且不存在异形，生成异形路径
    if (plank?.curveHoles?.length && !plank.specialShape) {
      plank.oPath = [
        [
          { x: 0, y: 0 },
          { x: plank.oRect.width, y: 0 },
          { x: plank.oRect.width, y: plank.oRect.height },
          { x: 0, y: plank.oRect.height },
        ],
      ]
      plank.path = [
        [
          { x: 0, y: 0 },
          { x: plank.rect.width, y: 0 },
          { x: plank.rect.width, y: plank.rect.height },
          { x: 0, y: plank.rect.height },
        ],
      ]
    }

    if (!plank.type) {
      plank.type = 'Plank'
    }
    const symbol = `${plank.matCode}:${plank.thick}:${plank.texture}`
    const symbolWithHighPlank = `${plank.matCode}:${plank.thick}:${plank.texture}:${high_plank}`
    // 将小板进行分类
    if (plankMap.has(symbolWithHighPlank)) {
      plankMap.get(symbolWithHighPlank).parts.push(plank)
    } else {
      const plankObj = {
        matCode: plank.matCode,
        thick: plank.thick,
        texture: plank.texture,
        symbol: symbol,
        parts: [plank],
        isUnfold: plank.isUnfold, // 是否在页面默认展开此分类, 显示出所有板件
        isAllSelect: true, // 是否选中此分类下的所有板件
        high_plank,
      }
      plankMap.set(symbolWithHighPlank, plankObj)
    }
    let order_adr = ''
    if (plank.orderNo) {
      // 第三方跳转时可能address为空
      if (plank.address) {
        order_adr = `${plank.orderNo}-${plank.address}`
      } else {
        order_adr = plank.orderNo
      }
    } else {
      // 没有订单号的时候展示项目地址
      order_adr = plank.address ?? ''
    }
    if (plank.groupNo) {
      order_adr = plank.groupNo
    }
    if (!orderNo_address.includes(order_adr) && order_adr) {
      orderNo_address.push(order_adr)
      ;(
        Object.keys(
          tempSamPleData
        ) as unknown as (keyof typeof tempSamPleData)[]
      ).forEach((key) => {
        tempSamPleData[key] += `,${plank[key]}`
      })
    }
  }
  arr = Array.from(plankMap.values())
  let newOrderInfo = Object.assign(orderInfo, {
    order_address: orderNo_address.join(','),
  })
  if (window.sessionStorage.getItem('thinkerx_material')) {
    newOrderInfo.order_address = dealGuimenFGNo(data).join(',')
  }
  const newArr = calcAllAreaAmount(arr)
  const plankList = dealPlankListLabelID(dealMaterialList(newArr))

  // 清空收集的plankNum
  uniquePlankNum.plankNumCollect.clear()
  return {
    plankList,
    orderInfo: newOrderInfo,
  }
}

// 拆分封边信息
export const splitEdgeInfo = (edgeInfo: string) => {
  let left = Number(
    edgeInfo.substring(edgeInfo.indexOf('←') + 1, edgeInfo.indexOf('↓'))
  )
  let bottom = Number(
    edgeInfo.substring(edgeInfo.indexOf('↓') + 1, edgeInfo.indexOf('→'))
  )
  let right = Number(
    edgeInfo.substring(edgeInfo.indexOf('→') + 1, edgeInfo.indexOf('↑'))
  )
  let top = Number(edgeInfo.substring(edgeInfo.indexOf('↑') + 1))
  return {
    leftEdge: left,
    rightEdge: right,
    frontEdge: top,
    backEdge: bottom,
  }
}

// 生成simplePlankNum
export const generateSimplePlankNum = (part: PartType) => {
  const { simplePlankNum: preSimplePlankNum, plankID, orderNo, roomID } = part
  if (preSimplePlankNum && preSimplePlankNum !== 'None') return
  let simplePlankNum = `000-${plankID}`
  if (orderNo && roomID) {
    const [first] = orderNo.split(/[*\-_/]/).slice(-1)
    const last = roomID.toString().substring(0, 2)
    simplePlankNum = `${first.substring(0, 3)}${last}-${plankID}`
  }
  return {
    simplePlankNum,
    simplePlankNumF: simplePlankNum + 'K',
  }
}

// 计算所有板件的总面积和总数量
export const calcAllAreaAmount = (data: PlankType[]) => {
  for (let i = 0; i < data.length; ++i) {
    let totalArea = 0
    let totalAmount = 0
    for (let k = 0; k < data[i].parts.length; ++k) {
      let plank = data[i].parts[k]
      let oRect = plank.oRect
      // 除以100w是为了从mm换算成米
      totalArea +=
        (Number(oRect.width) * Number(oRect.height) * Number(plank.amount)) /
        1000000
      totalAmount += Number(plank.amount)
    }
    data[i].totalArea = toDecimal(totalArea, 2)
    data[i].totalAmount = totalAmount
  }
  return data
}

// 计算一类板件的总的数量
export const calculatePlankAmount = (plank: PlankType) => {
  let result = 0
  plank.parts.forEach((part) => {
    result += Number(part.amount)
  })
  return result
}

// 计算一类板件的总面积
export const calculatePlankArea = (plank: PlankType) => {
  let result = 0
  plank.parts.forEach((part) => {
    result += part.specHeight * part.specWidth * part.amount
  })
  return Number((result / 1000000).toFixed(2))
}
